import { render, staticRenderFns } from "./HeroBanner.vue?vue&type=template&id=4aba8a96&scoped=true&"
import script from "./HeroBanner.vue?vue&type=script&lang=ts&"
export * from "./HeroBanner.vue?vue&type=script&lang=ts&"
import style0 from "./HeroBanner.vue?vue&type=style&index=0&id=4aba8a96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aba8a96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsImageOrFallback: require('/var/www/packages/theme/components/atoms/ImageOrFallback.vue').default,AtomsKEButton: require('/var/www/packages/theme/components/atoms/KEButton.vue').default,MoleculesCmsCustomButtonWrapper: require('/var/www/packages/theme/components/molecules/CmsCustomButtonWrapper.vue').default,IconsChevronLeft16px: require('/var/www/packages/theme/components/icons/chevron-left/ChevronLeft16px.vue').default,IconsChevronRight16px: require('/var/www/packages/theme/components/icons/chevron-right/ChevronRight16px.vue').default,MoleculesCmsUrlWrapper: require('/var/www/packages/theme/components/molecules/CmsUrlWrapper.vue').default})
